import { JSON_DEL, JSON_GET, JSON_POST, JSON_PUT } from './axiosConfig';

// 获取支付方式
export function apiGetPayType (params: any) {
  return JSON_GET('/ultra/api/v1/wechat/game/pay/results', params);
}

// 新增支付方式
export function apiAddPayType (params: any) {
  return JSON_POST('/ultra/api/v1/wechat/game/pay/result', params, true, true);
}

// 修改支付方式
export function apiUpdatePayType (params: any) {
  return JSON_PUT('/ultra/api/v1/wechat/game/pay/result', params, true, true);
}

// 获取所有支付方式
export function apiGetAllPayType(params: any) {
  return JSON_GET('/ultra/api/v1/wechat/game/pay/result/names', params);
}

// 策略列表
export function apiGetPayRules(params: any) {
  return JSON_GET('/ultra/api/v1/wechat/game/pay/strategies', params);
} 

// 新增策略
export function apiAddPayRule(params:any) {
  return JSON_POST('/ultra/api/v1/wechat/game/pay/strategy', params, true, true);
}

// 修改策略
export function apiUpdatePayRule(params: any) {
  return JSON_PUT('/ultra/api/v1/wechat/game/pay/strategy', params, true, true);
}

// 启用/禁用策略
export function apiToggleRuleStatus(params: any) {
  return JSON_DEL('/ultra/api/v1/wechat/game/pay/strategy', params, true, true);
}

// 获取策略详情
export function apiGetRuleDetail(params: any) {
  return JSON_GET('/ultra/api/v1/wechat/game/pay/strategy', params);
}

// 测试表达式
export function apiTestRule(params: any) {
  return JSON_POST('/ultra/api/v1/wechat/game/pay/strategy/eval/test', params);
}